<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.SKUS') }}
    </p>
    <div class="sm-content m-t-30">
      <section class="table-content-part">
        <div class="d-flex justify-content-between">
          <div class="search-bar">
            <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
            <v-text-field
              v-model="search"
              single-line
              hide-details
              class="search-form"
            ></v-text-field>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="profile_list"
          class="cus-table editable-table main-table striped-table"
          item-key="id"
        >
          <template v-slot:[`item.active`]="{ item }">
            <div style="display: flex; justify-content: center;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.active"></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.required`]="{ item }">
            <div style="display: flex; justify-content: center;">
              <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.required"></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.edit`]="{ }">
            <v-icon medium color="dark" @click="showModal('edit')">edit</v-icon>
          </template>
        </v-data-table>
      </section>

      <div class="content-margin m-b-40 d-flex">
        <button class="primary-btn primary-btn_sm m-t-30 bg-yellow" @click="showModal('create')">
          {{ $t("COMMON.NEW") }}
        </button>
      </div>
    </div>

    <!-- modal -->
    <modal
      :width="500"
      :adaptive="true"
      class="cp-product-codes_modal CEModal modal-scroll-bar"
      name="createEditModal"
    >
      <div class="modal-filter m-b-20" v-if="modalType=='edit'">
        <label>{{ $t('MANAGEMENT.SELECT_SKU') }}</label>
        <div class="icon-input">
          <input type="text" class="input" />
          <img src="/assets/img/icons/search-light.svg" alt="">
        </div>
      </div>
      <div class="modal-filter m-b-20" v-else-if="modalType=='create'">
        <label>{{ $t('MANAGEMENT.ENTER_NEW_PART_NUMBER_THERE') }}</label>
        <input type="text" class="input" />
      </div>
      <div class="d-flex justify-content-between w-75 m-b-30">
        <div>
          <div class="m-b-30">
            <input type="radio" name="sort_order"  />
            <label for="sort_order_123" class="px10">{{ $t('COMMON.ACTIVE') }}</label>
          </div>
          <div>
            <input type="radio" name="sort_order"  />
            <label for="sort_order_123" class="px10">{{ $t('MANAGEMENT.REQUIRED') }}</label>
          </div>
        </div>
        <div>
          <div class="m-b-30">
            <input type="radio" name="sort_order"  />
            <label for="sort_order_123" class="px10">{{ $t('MANAGEMENT.OFF') }}</label>
          </div>
          <div>
            <input type="radio" name="sort_order"  />
            <label for="sort_order_123" class="px10">{{ $t('MANAGEMENT.OPTIONAL') }}</label>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <button class="primary-btn primary-btn_sm">
          {{ $t("COMMON.NEW") }}
        </button>
        <button class="primary-btn primary-btn_sm bg-red2" @click="closeModal">
          {{ $t("MANAGE_SHANIV.VOID") }}
        </button>
      </div>
    </modal>
  </main> 
</template>

<script>
export default {
  name: 'CPProductCodes',
  data() {
    return {
      search: '',
       headers: [
        {
          text: this.$t('MANAGEMENT.NAME_OF_SKU'),
          align: 'center',
          sortable: false,
          value: 'sku_name',
        },
        {
          text: this.$t('COMMON.ACTIVE'),
          align: 'center',
          sortable: false,
          value: 'active',
        },
        {
          text: this.$t('MANAGEMENT.REQUIRED'),
          align: 'center',
          sortable: false,
          value: 'required',
        },
        {
          text: this.$t('MANAGEMENT.REQUIRED'),
          align: 'center',
          sortable: false,
          value: 'edit',
        }
      ],
      profile_list: [
        {
          sku_name: 'iCount',
          active: true,
          required: true,
          edit: ''
        },
        {
          sku_name: 'דלק',
          active: false,
          required: false,
          edit: ''
        },
        {
          sku_name: 'iCount',
          active: true,
          required: false,
          edit: ''
        }
      ],
      modalType: 'edit'
    }
  },
  methods: {
    showModal(type) {
      this.modalType = type;
      this.$modal.show("createEditModal");
      $("html").css("overflow", "hidden");
    },
    closeModal() {
      this.$modal.hide("createEditModal");
      $("html").css("overflow", "auto");
    },
  },
  computed: {
  },
}
</script>

<style lang="scss">
  .cp-product-codes_modal .vm--modal {
    padding: 40px;
    .modal-filter {
      max-width: 280px;
    }
  }
</style>